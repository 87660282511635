// STARTUP
export const STARTUP = 'STARTUP'
export const RESET = 'RESET'

// AUTH
export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'
export const VERIFY_CODE = 'VERIFY_CODE'
export const REFRESH = 'REFRESH'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const SET_TOKEN = 'SET_TOKEN'
export const RESET_ERRORS = 'RESET_ERRORS'

// CARDS
export const GET_CARDS_PRODUCTS = 'GET_CARDS_PRODUCTS'
export const GET_CARDS_ALL = 'GET_CARDS_ALL'
export const GET_CARDS_LATEST = 'GET_CARDS_LATEST'
export const GET_INVOICES_CARD = 'GET_INVOICES_CARD'
export const GET_ALL_INVOICES_CARD = 'GET_ALL_INVOICES_CARD'
export const GET_INVOICES_DOWNLOAD = 'GET_INVOICES_DOWNLOAD'
export const GET_INVOICES_PRINT = 'GET_INVOICES_PRINT'
export const GET_INVOICES_STATEMENS = 'GET_INVOICES_STATEMENS'
export const GET_ALL_STATEMENS_CARD = 'GET_ALL_STATEMENS_CARD'
export const GET_INVOICES_STATEMENS_DOWNLOAD = 'GET_INVOICES_STATEMENS_DOWNLOAD'
export const GET_INVOICES_STATEMENS_PRINT = 'GET_INVOICES_STATEMENS_PRINT'
export const APPLY_CARD = 'APPLY_CARD'
export const MANAGE_CARD_NAME = 'MANAGE_CARD_NAME'
export const VIEW_PIN = 'VIEW_PIN'
export const CHANGE_PIN = 'CHANGE_PIN'
export const LOST_CARD = 'LOST_CARD'
export const BLOCK_CARD = 'BLOCK_CARD'
export const GET_CARD_STEATMENTS_VIEW = 'GET_CARD_STEATMENTS_VIEW'
export const GET_CARD_INVOICES_VIEW = 'GET_CARD_INVOICES_VIEW'
export const RESET_DATA = 'RESET_DATA'
export const RESET_CARDS_ERRORS = 'RESET_CARDS_ERRORS'

// CONTENT
export const GET_CONTENT_FEED = 'GET_CONTENT_FEED'
export const GET_CONTENT_SERVICES = 'GET_CONTENT_SERVICES'
export const GET_CONTENT_TEXT_CONTACT = 'GET_CONTENT_TEXT_CONTACT'
export const GET_CONTENT_TEXT_TARIFF = 'GET_CONTENT_TEXT_TARIFF'
export const GET_CONTENT_TEXT_SITETC = 'GET_CONTENT_TEXT_SITETC'
export const GET_CONTENT_TEXT_CARDSTC = 'GET_CONTENT_TEXT_CARDSTC'
export const GET_CONTENT_TEXT_COOKIES = 'GET_CONTENT_TEXT_COOKIES'
export const GET_CONTENT_TEXT_PRIVACY = 'GET_CONTENT_TEXT_PRIVACY'


// LETTERS
export const GET_LETTERS = 'GET_LETTERS'
export const POST_LETTERS = 'POST_LETTERS'
export const GET_LATTER_CONTENT = 'GET_LATTER_CONTENT'
export const RESET_READED_LETTER = 'RESET_READED_LETTER'
export const GET_LATTER_DOWNLOAD = 'GET_LATTER_DOWNLOAD'
export const GET_LATTER_PRINT = 'GET_LATTER_PRINT'
export const DELETE_LETTER = 'DELETE_LETTER'
export const LETTER_TO_ARCHIVE = 'LETTER_TO_ARCHIVE'
export const GET_LETTERS_COUNT = 'GET_LETTERS_COUNT'
export const SET_LETTERS_COUNT = 'SET_LETTERS_COUNT'

// PROFILE
export const GET_PROFILE = 'GET_PROFILE'
export const POST_PROFILE = 'POST_PROFILE'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'