import React, { createRef, useEffect, useRef, useState } from 'react';
import { CardSlider } from '../../components/CardSlider';
import { screenHOC } from '../HOC/screenHOC';
import CardOption from './components/CardOption';
import Input from './components/Input';
import WriteIcon from '../../assets/images/ManageCards/write.png';
import AttentionIcon from '../../assets/images/ManageCards/attention.png';
import EyeIcon from '../../assets/images/ManageCards/eye.png';
import KeyIcon from '../../assets/images/ManageCards/key.png';
import LockIcon from '../../assets/images/ManageCards/lock.png';
import PasswordIcon from '../../assets/images/ManageCards/password.png';
import InsigniaCard from '../../assets/images/ManageCards/insignia-card.png';
import NewCard from '../../assets/images/ManageCards/group.png';
import './ManageCards.css';
import { Modal } from '../../components/Modal';
import Radio from './components/Radio';
import Card from '../../assets/images/royal-digital-card.png';
import { connect } from 'react-redux';
import classNamesBind from 'classnames/bind';
import { insertSpaces } from '../../redux/helpers';
import { baseURL } from '../../api/api';
// <<<<<<< HEAD
// import { cardManageBlockAction, changePinAction, getCardsAll, getCardsLatest, getCardSteatmentsViewAction, getInvoicesCardAction, lostCardAction, manageCardNameAction, resetCardsError, resetData, viewPinAction } from '../../redux/actions/cardsActions';
// =======
import { cardManageBlockAction, changePinAction, getCardInvoicesViewAction, getInvoicesStatemensDownloadAction, getInvoicesDownloadAction, getCardsAll, getCardsLatest, getCardSteatmentsViewAction, getInvoicesCardAction, lostCardAction, manageCardNameAction, resetCardsError, resetData, viewPinAction } from '../../redux/actions/cardsActions';
// >>>>>>> 5a66796e09a1443ba420266d1dd85d799fbcb16a
import { isNil } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Preloader } from '../../components/Preloader';
import { Button } from '../../components/Button';
import MessagePopUp from '../../components/MessagePopUp';

// export const manageCardName = ({id, data}) => API.post({
    // data: {
    //     currentName: data.currentName,
    //     newName: data.newName,
    //     newNameConfirm: data.newNameConfirm
    //   },

// export const viewPin = (id) => API.post({
// export const changePin = ({id, data}) => API.post({
//     data: {
//     currentPin: data.currentPin,
//     newPin: data.newPin,
//     newPinConfirm: data.newPinConfirm,
//   },
// export const lostCard = ({id, data}) => API.post({
//     data: {
//     type: "Lost",
//     comment: data.comment
//   },
// export const blockCard = (id) => API.post({

const OnSwiper = null

const btnstyles = {
    borderRadius: '8px',
    border: '1px solid transparent',
    backgroundColor: '#272727',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '400px',
    marginBottom: '1rem',
    textTransform: 'unset',
    padding: 0
}

const ManageCards = ({
    dispatch,
    manageCardNameStatus,
    manageCardNameError,
    viewPinStatus,
    viewPinError,
    changePinStatus,
    changePinError,
    lostCardStatus,
    lostCardError,
    blockCardStatus,
    blockCardError,
    cardsArr,
    history,
    invoices,
    steatments,
    statementsData,
    invoicesData,
    productsCards
}) => {

    const CurrentNameRef = useRef()
    const NewNameRef = useRef()
    const RetypeNewNameRef = useRef()

    const CurrentPinRef = useRef()
    const NewPinRef = useRef()
    const RetypeNewPinRef = useRef()


    const [selectedCard, setSelectedCard] = useState({});
    const [shownCard, setShownCard] = useState(0);
    const [blockUnblock, setBlockUnblock] = useState('')
    const [lostType, setLostType] = useState('')
    const [LostComment, setLostComment] = useState('')
    const [error, setError] = useState('');
    const [padding, setPadding] = useState(null);

    // ICA
    const [icaCard, setIcaCard] = useState({});

    // Errors
    const [newNameError, setNewNameError] = useState(false)
    const [pinError, setPinError] = useState(false)
    const [blockUnblockError, setBlockUnblockError] = useState(false)
    const [lostStollenError, setLostStollenError] = useState(false);

    // End of errors
    const [showNameYourCardModal, setShowNameYourCardModal] = useState(false);
    const [showSuccessfullyChangeNameModal, setShowSuccessfullyChangeNameModal] = useState(false);
    const [showViewYourPINModal, setShowViewYourPINModal] = useState(false);
    const [showViewYourPINModalError, setShowViewYourPINModalError] = useState(false);
    const [showChangeYourPINModal, setShowChangeYourPINModal] = useState(false);
    const [showSuccessfullyChangeYourPINModal, setShowSuccessfullyShowChangeYourPINModal] = useState(false);
    const [showUnblockModal, setShowUnblockModal] = useState(false);
    const [showSuccessfullyUnblockModal, setShowSuccessfullyUnblockModal] = useState(false);
    const [showLostOrStolen, setShowLostOrStolen] = useState(false);
    const [showSuccessfullyLostOrStolenModal, setShowSuccessfullyLostOrStolenModal] = useState(false);
    const [cardOptions, setCardOptions] = useState(false);

    const handleCardSelect = (card, index, e) => {
        localStorage.setItem('selectedCardIndex', index)
        localStorage.setItem('selectedCard', JSON.stringify(card))
        setSelectedCard(card);
        setShownCard(index);
        /* @todo: remove?
        if (card){
            dispatch(
                getInvoicesCardAction(card.productId || 1)
            )
        }*/
    };
    const isMobile = window.innerWidth <= 768;

    useEffect(() =>{
        const index = localStorage.getItem('selectedCardIndex');
        const card = localStorage.getItem('selectedCard');

        if (index) {
            setShownCard(index);
        }

        if (card) {
            setSelectedCard(card);
        }

        if(selectedCard){
            dispatch(
                getInvoicesCardAction(selectedCard.productId || 1)
            )
        }
    }, [])

    const handleICASelect = (card) =>{
        setIcaCard(card);
    }

    useEffect(() => {
        if (cardsArr && cardsArr.length) {
            if (selectedCard) {
                const found = cardsArr.filter(item => item.id === selectedCard.id)[0];
                if (!found) setSelectedCard(cardsArr[0])
            } else {
                setSelectedCard(cardsArr[0])
            }
        }
        //cardsArr && cardsArr.length && setSelectedCard(cardsArr[0])
    }, [cardsArr])

    useEffect(() => {
        if (CurrentNameRef.current) {
            CurrentNameRef.current.value = selectedCard.cardName
        }
    }, [selectedCard])

    useEffect(() => {
        // console.log('[ManageCards] [useEffect location]');
        dispatch(
            resetData()
        )
        dispatch(
            getCardsAll()
        )

        if (history.location?.state?.item) {
            setSelectedCard(history.location.state.item)
            setShownCard(history.location.state.index)
            // refs[history.location.state.item.id]?.current?.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'start',
            // });
        } else {
            if (isMobile) {
                history.push('/cards');
            }
        }

        if (history.location?.state?.cardOptions) {
            setCardOptions(true)
        }
    }, [])

    useEffect(() => {
        if (window.location.pathname === '/') {
            setCardOptions(false)
        }
    }, [window.location.pathname])

    // card actions
    useEffect(() => {
        if (!isNil(manageCardNameStatus)) {
            setShowNameYourCardModal(false)
            setShowSuccessfullyChangeNameModal(true)
            dispatch(
                getCardsAll()
            )
        }
    }, [manageCardNameStatus])

    useEffect(() => {
        if (!isNil(viewPinStatus)) {
            setShowViewYourPINModal(true)
        }
    }, [viewPinStatus])

    useEffect(() => {
        if (!isNil(viewPinError) || viewPinError === '') {
            setShowViewYourPINModalError(true)
        }
    }, [viewPinError])

    useEffect(() => {
        if (!isNil(changePinStatus)) {
            setShowChangeYourPINModal(false)
            setShowSuccessfullyShowChangeYourPINModal(true)
        }
    }, [changePinStatus])

    useEffect(() => {
        if (!isNil(blockCardStatus)) {
            setShowUnblockModal(false)
            setShowSuccessfullyUnblockModal(true)
        }
    }, [blockCardStatus])

    useEffect(() => {
        if (!isNil(lostCardStatus)) {
            setShowLostOrStolen(false)
            setShowSuccessfullyLostOrStolenModal(true)
        }
    }, [lostCardStatus])

    const handleManageCardName = () => {
        const data = {
            currentName: CurrentNameRef.current.value,
            newName: NewNameRef.current.value,
            newNameConfirm: RetypeNewNameRef.current.value
        }
        if (data.currentName === data.newName) {
            setNewNameError('You provided the same card name that this card has now.')
        } else if (data.newName !== data.newNameConfirm) {
            setNewNameError('New name doesn`t match the retyped one.')
        } else if (data.currentName && !data.newName) {
            setNewNameError('Please provide new name. ')
        } else if (data.currentName && data.newName && ! data.newNameConfirm) {
            setNewNameError('Please retype new name.')
        } else {
            dispatch(
                manageCardNameAction({
                    id: selectedCard.id,
                    data: {
                        currentName: CurrentNameRef.current.value,
                        newName: NewNameRef.current.value,
                        newNameConfirm: RetypeNewNameRef.current.value
                    }
                })
            );
            
            selectedCard.cardName = NewNameRef.current.value;
        }
    }

    const handleViewPin = () => {
        setShowViewYourPINModal(!showViewYourPINModal)
    }

    const handleChangePin = () => {
        const data = {
            currentPin: CurrentPinRef.current.value,
            newPin: NewPinRef.current.value,
            newPinConfirm: RetypeNewPinRef.current.value,
        }
        if (!data.currentPin) {
            setPinError('Pin must have 4 digits.')
        } else if (data.currentPin === data.newPin) {
            setPinError('New pin and current pin must be different.')
        } else if (data.newPin.length === 0 ||  data.newPinConfirm.length === 0) {
            setPinError('Pin must have 4 digits.')
        } else if (data.newPin !== data.newPinConfirm) {
            setPinError('New pin doesn`t match retyped one.')
        } else if (
            data.currentPin.length !== 4 ||
            data.newPin.length !== 4 ||
            data.newPinConfirm.length !== 4
        ) {
            setPinError('Pin must have 4 digits.')
        } else if (
            data.currentPin &&
            data.currentPin.length === 4 &&
            data.currentPin !== data.newPin &&
            data.newPin === data.newPinConfirm &&
            data.newPin.length === 4 &&
            data.newPinConfirm.length === 4
        ) {
            dispatch(
                changePinAction({
                    id: selectedCard.id,
                    data
                })
            )
            setPinError(false)
        }

        // setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
    }

    const handleBlockUnblock = () => {
        if (!blockUnblock) {
            setBlockUnblockError(true)
        } else {
            setBlockUnblockError(false)
            dispatch(
                cardManageBlockAction({
                    id: selectedCard.id,
                    data: {
                        type: blockUnblock
                    }
                })
            )
        }
    }

    const handleLostOrStollen = () => {
        if (!lostType) {
            setLostStollenError(true)
        } else {
            dispatch(
                lostCardAction({
                    id: selectedCard.id,
                    data: {
                        type: lostType,
                        comment: LostComment
                    }
                })
            )
            setLostStollenError(false)
        }
    }

    const createError = (text) => (
        <div
            className={'auth-error auth-input-error' + (text != '' ? ' auth-has-error' : '')}
            style={{marginTop: ''}}
        >
            <div className="auth-error-i">!</div>
            <div className="auth-error-t">{text}</div>
        </div>
    )

    const renderMultipleErrors = (errors) => {
        return Object.values(errors).map(i => createError(i))
    }

    const handleViewStatement = () => {
        dispatch(
            getInvoicesStatemensDownloadAction(steatments[0].id)
        )
        setSelectedCard(selectedCard)
        setShownCard(shownCard)
    }
    const handleViewInvoice = () => {
        dispatch(
            getInvoicesDownloadAction(invoices[0].id)
        )
        setSelectedCard(selectedCard)
        setShownCard(shownCard)
    }

    const handleManageCards = () => {
        history.push('/manage-cards', { item: selectedCard, index: shownCard, cardOptions: true });
        setCardOptions(true);
        // history.push('/cards', {selectedCard, shownCard})
    }

    const renderBackgroundImg = (item) => {
      let bg = '';
  
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          bg = productsCards[i].images?.front
        }
      }
  
      return `${baseURL}/${bg}`;
    }

    const getColor = (item) => {
      let color = '#ffffff';
  
      for (let i = 0; i < productsCards.length; i++) {
        if (productsCards[i].id === item.productId) {
          color = productsCards[i].textColor
        }
      }
      return color
    }

    const renderCardNumber = number => {
      const result = []
      if (number) {
        for (let i = 0; i < number.length; i++) {
          if (i % 4 === 0) {
            result.push(' ')
            result.push(number[i])
          } else {
            result.push(number[i])
          }
        }
        return result.join('')
      } else {
        return ''
      }
    }

    const renderOneCard = (item) => {
        return (
            <div
                className={classNamesBind('card-item')}
                style={{ backgroundImage: `url(${renderBackgroundImg(item)})` }}
            >
                <div className='currency due-space'>
                    <div style={{ color: getColor(item) }}>{item.currency}</div>
                </div>

                <p
                    className='card-item-number'
                    style={{ color: getColor(item) }}
                >
                    {renderCardNumber(item.cardNumber)}
                </p>

                <p
                    className='card-item-number'
                    style={{ color: getColor(item) }}
                >
                    {item.cardName}
                </p>
            </div>
        );
    };

    const renderCurrentCard = () => {
        const index = history?.location?.state?.index || 0;

        return (
            <div className="manage-cards-container">
                {renderOneCard(selectedCard)}

                <div className="cards-list-mobile--card-actions">
                    <div className="cards-button cards-button-name">
                        <Button
                            text={'Name Your Card'}
                            onClick={() => setShowNameYourCardModal(!showNameYourCardModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-pin">
                        <Button
                            text={'Change Your PIN'}
                            onClick={() => setShowChangeYourPINModal(!showChangeYourPINModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-block">
                        <Button
                            text={'Block / Unblock'}
                            onClick={() => setShowUnblockModal(!showUnblockModal)}
                        />
                    </div>

                    <div className="cards-button cards-button-stolen">
                        <Button
                            text={'Lost or Stolen Card'}
                            onClick={() => setShowLostOrStolen(!showLostOrStolen)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderButtons = () => {
        if (selectedCard) {
            if (cardOptions) {
                return (
                    <div>
                        <CardOption
                            icon={WriteIcon}
                            title="Name Your Card"
                            description="Create custom name"
                            onClick={() => setShowNameYourCardModal(!showNameYourCardModal)}
                        />
                        {/*<CardOption
                            icon={EyeIcon}
                            title="View Your PIN"
                            description="Receive a PIN reminder by SMS"
                            onClick={() => {
                                dispatch(
                                    viewPinAction(selectedCard.id)
                                )
                            }}
                        />*/}
                        <CardOption
                            icon={KeyIcon}
                            title="Change Your PIN"
                            description="For security you will be issued a new card"
                            onClick={() => setShowChangeYourPINModal(!showChangeYourPINModal)}
                        />
                        <CardOption
                            icon={LockIcon}
                            title="Block / Unblock"
                            description="Temporarily or permanently block your card"
                            onClick={() => setShowUnblockModal(!showUnblockModal)}
                        />
                        <CardOption
                            icon={AttentionIcon}
                            title="Lost or Stolen Card"
                            description="Report your card lost or stolen"
                            onClick={() => setShowLostOrStolen(!showLostOrStolen)}
                        />
                    </div>
                )
            } else {
                if (selectedCard && icaCard.cardNumber !== "ICA" ) {
                    return (
                        <div className="content_buttons">
                            {invoices && invoices[0] ? <Button text="View Invoice" buttonStyles={(statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'}} textStyles={{textTransform: 'unset'}} onClick={handleViewInvoice}/> : null}
                            {steatments && steatments[0] ? <Button text="View Statement" buttonStyles={(statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'}} textStyles={{textTransform: 'unset'}} onClick={handleViewStatement}/> : null}
                            <Button text="Manage Card" buttonStyles={(statementsData || invoicesData) ? {...btnstyles} : {width: '400px', marginBottom: '1rem', borderRadius: '8px'}} textStyles={{textTransform: 'unset'}} onClick={handleManageCards} />
                        </div>
                    )
                }
            }
        }
    }

    if (cardsArr) {
        return (
            <div style={{ width: '100%' }}>
                <div className="Content show-desktop">
                    <div style={{ marginBottom: '50px', width: '100%', }}>
                        <CardSlider
                            visibleCount={7}
                            cardClassName='smallCardsSliderCard-manageCards'
                            cardSliderClassName='smallCardsSlider-manageCards'
                            onSelectCard={handleCardSelect}
                            onSelectICA={handleICASelect}
                            cardsArr={cardsArr}
                            toggleCards
                            showCardId={shownCard}
                            dontRotate
                            goToSlideNumber={history?.location?.state?.index && Math.ceil((history.location.state.index + 1 )/ 3)}
                            renderButtons={renderButtons}
                            showICA
                        />
                    </div>
                </div>

                <div className="Content-mobile show-mobile">
                    <h2 className="cards-header">Manage the Card</h2>

                    {renderCurrentCard()}
                </div>

                {/* {  Name your card modal } */}
                <Modal
                    title="Name Your Card"
                    hideButton={true}
                    modalOpen={showNameYourCardModal}
                    content={
                        <div>
                            <Input
                                label="Current Name"
                                ref={CurrentNameRef}
                                value={selectedCard?.cardName || ''}
                            />
                            <Input
                                label="New Name"
                                ref={NewNameRef}
                                maxLength={21}
                            />
                            <Input
                                label="Retype New Name"
                                ref={RetypeNewNameRef}
                                maxLength={21}
                            />
                        </div>
                    }
                    onClose={() => {
                        setShowNameYourCardModal(false)
                        dispatch(resetCardsError())
                        setNewNameError(false)
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowNameYourCardModal(false)
                                dispatch(resetCardsError())
                            },
                            text: 'Cancel',
                            buttonStyles: {
                                width: '159px',
                                backgroundColor: 'rgb(255 255 255)',
                                border: 'solid 1px #000000',
                                // marginBottom: 45,
                            },
                            textStyles: { color: 'black' },
                            whiteButton: true,
                        },
                        {
                            onClick: handleManageCardName,
                            text: 'Save',
                            buttonStyles: { width: '159px', marginBottom: 0},
                            textStyles: {},

                        },
                    ]}
                    // customButtonsBlockStyle={{marginBottom: 45}}
                    errorAfterContent={
                        <>
                        {
                            manageCardNameError && createError('Something went wrong. Please try again or contact customer service.')
                        }
                        {
                            !manageCardNameError && newNameError && createError(newNameError)
                        }
                        </>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully changed the name of your card Modal} */}
                <Modal
                    hideButton={true}
                    modalOpen={showSuccessfullyChangeNameModal}
                    content={
                        <div className="modal-unblock">
                            You have successfully changed the name of your card
                        </div>
                    }
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    onClose={() => {
                        setShowSuccessfullyChangeNameModal(!showSuccessfullyChangeNameModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyChangeNameModal(!showSuccessfullyChangeNameModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            // buttonStyles: {
                            //     marginBottom: 76
                            // }
                        },
                    ]}
                />

                {/* {View Your PIN Modal} */}
                {/*<Modal
                    title="View Your PIN"
                    hideButton={true}
                    modalOpen={showViewYourPINModal}
                    content={
                        <div style={{ marginTop: '-30px', width: '518px' }}>
                            <div style={{ fontSize: '16px' }}>XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}</div>
                            <img style={{ marginTop: '50px' }} src={PasswordIcon} alt="password icon" />
                            <div style={{ marginTop: '15px' }}>
                                A PIN reminder has been sent to you by SMS
                            </div>
                        </div>
                    }
                    onClose={() => setShowViewYourPINModal(!showViewYourPINModal)}
                    buttons={[
                        {
                            onClick: handleViewPin,
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: 150
                            }
                        },
                    ]}
                />*/}

                {/* {View Your PIN Modal Error} */}
                {/*<Modal
                    title="View Your PIN"
                    hideButton={true}
                    modalOpen={showViewYourPINModalError}
                    content={
                        <div style={{ marginTop: '30px', width: '518px' }}>
                            <div style={{ marginTop: '15px' }}>
                                <div className={'auth-error auth-input-error' + ' auth-has-error'}>
                                    <div className="auth-error-i">!</div>
                                    <div className="auth-error-t" style={{textAlign: 'left'}}>Something went wrong. Please try again or contact customer service.</div>
                                </div>
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowViewYourPINModalError(false)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowViewYourPINModalError(false)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: 150
                            }
                        },
                    ]}
                />*/}

                {/* {Change Your PIN Modal} */}
                <Modal
                    title="Change Your PIN"
                    hideButton={true}
                    modalOpen={showChangeYourPINModal}
                    content={
                        <div>
                            <Input label="Current Pin" ref={CurrentPinRef} maxLength={4} minLength={4} type='number' />
                            <Input label="New Pin" ref={NewPinRef} maxLength={4} minLength={4} type='number' />
                            <Input label="Retype New Pin" ref={RetypeNewPinRef} maxLength={4} minLength={4} type='number' />
                        </div>
                    }
                    onClose={() => {
                        setShowChangeYourPINModal(!showChangeYourPINModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                dispatch(resetCardsError())
                                setShowChangeYourPINModal(!showChangeYourPINModal)
                            },
                            text: 'Cancel',
                            buttonStyles: {
                                width: '159px',
                                backgroundColor: 'rgb(255 255 255)',
                                border: 'solid 1px #000000',
                                // marginBottom: 32
                            },
                            whiteButton: true,
                            textStyles: { color: 'black' },
                        },
                        {
                            onClick: handleChangePin,
                            text: 'Save',
                            buttonStyles: { width: '159px' },
                            textStyles: {},
                        },
                    ]}
                    // customButtonsBlockStyle={{marginBottom: 32}}
                    errorAfterContent={
                        <>
                        {changePinError && createError('Something went wrong. Please try again or contact customer service.')}
                        {
                            !changePinError && pinError && createError(pinError)
                        }
                        </>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully changed the PIN Modal} */}
                <Modal
                    hideButton={true}
                    modalOpen={showSuccessfullyChangeYourPINModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            You have successfully changed the PIN of your card
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyShowChangeYourPINModal(!showSuccessfullyChangeYourPINModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            // buttonStyles: {
                            //     marginBottom: 76
                            // }
                        },
                    ]}
                />

                {/* {Block/Unblock Modal} */}
                <Modal
                    title="Block/Unblock"
                    hideButton={true}
                    modalOpen={showUnblockModal}
                    content={
                        <div className="modal-unblock">
                            <p className="show-mobile modal-unblock-description">
                                Here you may temporarily or permanently block your card
                            </p>

                            <div className="modal-unblock-inner">
                                <span className="show-mobile">Your Card</span>
                                XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}
                            </div>
                            
                            <div className="modal-unblock-radios">
                                <Radio name='block-unblock' title="Block" onClick={setBlockUnblock} />
                                <Radio name='block-unblock' title="Unblock" onClick={setBlockUnblock} />
                                {/*<Radio name='block-unblock' title="Block Temporarily" onClick={setBlockUnblock} />*/}
                            </div>
                        </div>
                    }
                    onClose={() => {
                        dispatch(resetCardsError())
                        setShowUnblockModal(!showUnblockModal)
                        setBlockUnblockError(false)
                    }}
                    customButtonsBlockClass='modal-unblock-buttons'
                    buttons={[
                        {
                            onClick: handleBlockUnblock,
                            text: 'Submit',
                        },
                    ]}
                    errorAfterContent={
                        <div style={{marginTop: 25, marginBottom: -15}}>
                        {blockCardError  &&   createError('Something went wrong. Please try again or contact customer service.')}
                        {!blockCardError && blockUnblockError && createError('Please select an action.')}
                        </div>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully Block/Unblock Modal} */}
                <Modal
                    title="Request Successful"
                    hideButton={true}
                    modalOpen={showSuccessfullyUnblockModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                Your changes have been successfully implemented. If you have any further
                                queries, please contact customer service for assistance. 24-hour phone
                                support: +44-203-151-7000 / +800-800-00-700
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyUnblockModal(!showSuccessfullyUnblockModal)
                        dispatch(resetCardsError())
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyUnblockModal(!showSuccessfullyUnblockModal)
                                dispatch(resetCardsError())
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: isMobile ? 0 : 121
                            }
                        },
                    ]}
                />

                {/* {Lost or Stolen Modal} */}
                <Modal
                    title="Lost or Stolen"
                    hideButton={true}
                    modalOpen={showLostOrStolen}
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                <span className="show-mobile">Your Card</span>
                                XXXX XXXX XXXX {selectedCard?.cardNumber?.slice(selectedCard?.cardNumber.length - 4, selectedCard?.cardNumber.length)}
                            </div>

                            <div className="modal-unblock-radios">
                                <Radio name='lost-or-stollen' title="Lost" onClick={setLostType} />
                                <Radio name='lost-or-stollen' title="Stolen" onClick={setLostType} />
                            </div>

                            <div className="modal-stolen-comment">
                                <label
                                    style={{
                                        float: 'left',
                                        marginTop: '12px',
                                        color: '#88888b',
                                        fontFamily: 'ProximaNova',
                                    }}
                                    htmlFor="textarea">
                                    Leave a comment
                                </label>
                                <textarea
                                    id="textarea"
                                    cols="40"
                                    value={LostComment}
                                    onChange={e => setLostComment(e.target.value)}
                                    rows="10"></textarea>
                            </div>
                        </div>
                    }
                    onClose={() => {
                        dispatch(resetCardsError())
                        setShowLostOrStolen(!showLostOrStolen)
                        setLostComment('')
                        setLostStollenError(false)
                    }}
                    customButtonsBlockClass='modal-stolen-buttons'
                    buttons={[
                        {
                            onClick: handleLostOrStollen,
                            text: 'Submit',
                        },
                    ]}
                    errorAfterContent={
                        <div style={{marginTop: '20px', marginBottom: '-15px'}}>
                            {lostCardError && createError('Something went wrong. Please try again or contact customer service.')}
                            {!lostCardError && lostStollenError && createError('Please select the cause.')}
                        </div>
                    }
                    disableDefaultHandler
                />

                {/* {Successfully Lost or Stolen Modal} */}
                <Modal
                    title="Your message has been sent"
                    hideButton={true}
                    modalOpen={showSuccessfullyLostOrStolenModal}
                    wrapperClassname="recovery-custom-modal login-success-modal"
                    content={
                        <div className="modal-unblock">
                            <div className="modal-unblock-inner">
                                One of our representatives will respond to you shortly.
                                <br />
                                <br />
                                24-hour phone support: +44-203-151-7000 / +800-800-00-700
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setShowSuccessfullyLostOrStolenModal(!showSuccessfullyLostOrStolenModal)
                        dispatch(resetCardsError())
                        setLostComment('')
                    }}
                    buttons={[
                        {
                            onClick: () => {
                                setShowSuccessfullyLostOrStolenModal(
                                    !showSuccessfullyLostOrStolenModal,
                                );
                                dispatch(resetCardsError())
                                setLostComment('')
                            },
                            text: 'OK',
                            buttonStyles: {
                                marginBottom: isMobile ? 0 : 150
                            }
                        },
                    ]}
                />
                {
                    window.location.pathname !== '/cards' &&
                        <MessagePopUp
                            pdfDoc={statementsData || invoicesData}
                            pdfType={statementsData ? 'Statement' : 'Invoice'}
                            onClose={() => dispatch(resetData())}
                        >
                            <div/>
                        </MessagePopUp>
                }
            </div>
        );
    } else {
        return <Preloader preloaderOpacity={1} />
    }
};

const mapStateToProps = state => ({
    manageCardNameStatus: state.cards.manageCardNameStatus,
    manageCardNameError: state.cards.manageCardNameError,
    viewPinStatus: state.cards.viewPinStatus,
    viewPinError: state.cards.viewPinError,
    changePinStatus: state.cards.changePinStatus,
    changePinError: state.cards.changePinError,
    lostCardStatus: state.cards.lostCardStatus,
    lostCardError: state.cards.lostCardError,
    blockCardStatus: state.cards.blockCardStatus,
    blockCardError: state.cards.blockCardError,
    cardsArr: state.cards.cardsAll,
    invoices: state.cards.invoices || [],
    steatments: state.cards.steatments || [],
    statementsData: state.cards.statementsData,
    invoicesData: state.cards.invoicesData,
    productsCards: state.cards.cardsProducts || [],
})

export default connect(mapStateToProps)(screenHOC(withRouter(ManageCards)));
