import React from 'react';
import { Link } from 'react-router-dom'
import '../ManageCards.css';

const CardOption = ({ title, icon, description, onClick }) => {
    return (
        <div className="Item">
            <div className="Icon">
                <img src={icon} alt="text" />
            </div>
            <Link to="#" onClick={onClick} style={{color: '#000000'}}>
                <div className="Title">{title}</div>
                <div className="Description">{description}</div>
            </Link>
        </div>
    );
};

export default CardOption;
