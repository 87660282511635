import {
  GET_CARDS_ALL,
  GET_CARDS_LATEST,
  GET_INVOICES_CARD,
  GET_INVOICES_DOWNLOAD,
  GET_INVOICES_PRINT,
  GET_INVOICES_STATEMENS,
  GET_INVOICES_STATEMENS_DOWNLOAD,
  GET_INVOICES_STATEMENS_PRINT,
  APPLY_CARD,
  MANAGE_CARD_NAME,
  VIEW_PIN,
  CHANGE_PIN,
  LOST_CARD,
  BLOCK_CARD,
  GET_CARD_STEATMENTS_VIEW,
  GET_CARD_INVOICES_VIEW,
  GET_CARDS_PRODUCTS,
  RESET_DATA,
  RESET_CARDS_ERRORS
} from "../constants"
import { downloadPDFViewByData, openPDFViewByData, printPDFViewByData } from "../helpers"

const initState = {}

export default function CardsReducer(state = initState, action) {
  switch (action.type) {
    case `${GET_CARDS_PRODUCTS}_REQUEST`: {
      return {
        ...state,
        cardsProducts: null,
        isFetching: true
      }
    }
    case `${GET_CARDS_PRODUCTS}_SUCCESS`: {
      return {
        ...state,
        cardsProducts: action.payload?.data,
        isFetching: false
      }
    }
    case `${GET_CARDS_PRODUCTS}_FAILED`: {
      return {
        ...state,
        cardsProducts: null,
        error: action?.payload?.data,
        isFetching: false
      }
    }
    
    case `${GET_CARDS_ALL}_REQUEST`: {
      return {
        ...state,
        cardsAll: null,
        isFetching: true
      }
    }
    case `${GET_CARDS_ALL}_SUCCESS`: {
      return {
        ...state,
        cardsAll: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CARDS_ALL}_FAILED`: {
      return {
        ...state,
        cardsAll: null,
        error: action.payload.data,
        isFetching: false
      }
    }

    case `${GET_CARDS_LATEST}_REQUEST`: {
      return {
        ...state,
        cardsLatest: null,
        isFetching: true
      }
    }
    case `${GET_CARDS_LATEST}_SUCCESS`: {
      return {
        ...state,
        cardsLatest: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_CARDS_LATEST}_FAILED`: {
      return {
        ...state,
        cardsLatest: null,
        error: action.payload?.data,
        isFetching: false
      }
    }

    case `${GET_INVOICES_CARD}_REQUEST`: {
      return {
        ...state,
        invoices: null,
        error: null,
        isFetching: true
      }
    }
    case `${GET_INVOICES_CARD}_SUCCESS`: {
      return {
        ...state,
        invoices: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_INVOICES_CARD}_FAILED`: {
      return {
        ...state,
        invoices: null,
        error: action.payload.data,
        isFetching: false
      }
    }


    case `${GET_INVOICES_DOWNLOAD}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_INVOICES_DOWNLOAD}_SUCCESS`: {
      const { data, name, reset, response } = action.payload;
      name && downloadPDFViewByData(response ? response.data : data, name)
      const d = response ? response.data : data
      console.log(d, reset);
      return {
        ...state,
        invoicesData: reset ? undefined : d,
        isFetching: false
      }
    }
    case `${GET_INVOICES_DOWNLOAD}_FAILED`: {
      return {
        ...state,
        isFetching: false
      }
    }


    case `${GET_INVOICES_PRINT}_REQUEST`: {
      return {
        ...state,
        isFetching: true
      }
    }
    case `${GET_INVOICES_PRINT}_SUCCESS`: {
      const { data } = action.payload;
      printPDFViewByData(data)
      return {
        ...state,
        isFetching: false
      }
    }
    case `${GET_INVOICES_PRINT}_FAILED`: {
      return {
        ...state,
        isFetching: false
      }
    }


    case `${GET_INVOICES_STATEMENS}_REQUEST`: {
      return {
        ...state,
        steatments: null,
        error: null,
        isFetching: true,
      }
    }
    case `${GET_INVOICES_STATEMENS}_SUCCESS`: {
      return {
        ...state,
        steatments: action.payload.data,
        isFetching: false
      }
    }
    case `${GET_INVOICES_STATEMENS}_FAILED`: {
      return {
        ...state,
        steatments: null,
        error: action.payload.data,
        isFetching: false
      }
    }


    case `${GET_INVOICES_STATEMENS_DOWNLOAD}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${GET_INVOICES_STATEMENS_DOWNLOAD}_SUCCESS`: {
      const { data, name, reset, response } = action.payload;
      name && downloadPDFViewByData(response ? response.data : data, name)
      const d = response ? response.data : data
      return {
        ...state,
        statementsData: reset ? undefined : d,
        isFetching: false
      }
    }
    case `${GET_INVOICES_STATEMENS_DOWNLOAD}_FAILED`: {
      return {
        ...state,
        isFetching: false
      }
    }


    case `${GET_INVOICES_STATEMENS_PRINT}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${GET_INVOICES_STATEMENS_PRINT}_SUCCESS`: {
      const { data } = action.payload;
      printPDFViewByData(data)
      return {
        ...state,
        isFetching: false
      }
    }
    case `${GET_INVOICES_STATEMENS_PRINT}_FAILED`: {
      return {
        ...state,
        isFetching: false
      }
    }

    case `${MANAGE_CARD_NAME}_REQUEST`: {
      return {
        ...state,
        manageCardNameStatus: null,
        manageCardNameError: null,
        isFetching: true,
      }
    }
    case `${MANAGE_CARD_NAME}_SUCCESS`: {
      return {
        ...state,
        manageCardNameStatus: action.payload.data,
        manageCardNameError: null,
        isFetching: false
      }
    }
    case `${MANAGE_CARD_NAME}_FAILED`: {
      return {
        ...state,
        manageCardNameStatus: null,
        manageCardNameError: action.payload?.data,
        isFetching: false
      }
    }


    case `${VIEW_PIN}_REQUEST`: {
      return {
        ...state,
        viewPinStatus: null,
        viewPinError: null,
        isFetching: true,
      }
    }
    case `${VIEW_PIN}_SUCCESS`: {
      return {
        ...state,
        viewPinStatus: action.payload.data,
        viewPinError: null,
        isFetching: false
      }
    }
    case `${VIEW_PIN}_FAILED`: {
      return {
        ...state,
        viewPinStatus: null,
        viewPinError: action.payload.data,
        isFetching: false
      }
    }


    case `${CHANGE_PIN}_REQUEST`: {
      return {
        ...state,
        changePinStatus: null,
        changePinError: null,
        isFetching: true,
      }
    }
    case `${CHANGE_PIN}_SUCCESS`: {
      return {
        ...state,
        changePinStatus: action.payload.data,
        changePinError: null,
        isFetching: false
      }
    }
    case `${CHANGE_PIN}_FAILED`: {
      return {
        ...state,
        changePinStatus: null,
        changePinError: action.payload.data,
        isFetching: false
      }
    }

    case `${LOST_CARD}_REQUEST`: {
      return {
        ...state,
        lostCardStatus: null,
        lostCardError: null,
        isFetching: true,
      }
    }
    case `${LOST_CARD}_SUCCESS`: {
      return {
        ...state,
        lostCardStatus: action.payload.data,
        lostCardError: null,
        isFetching: false
      }
    }
    case `${LOST_CARD}_FAILED`: {
      return {
        ...state,
        lostCardStatus: null,
        lostCardError: action.payload?.data,
        isFetching: false
      }
    }


    case `${BLOCK_CARD}_REQUEST`: {
      return {
        ...state,
        blockCardStatus: null,
        blockCardError: null,
        isFetching: true,
      }
    }
    case `${BLOCK_CARD}_SUCCESS`: {
      return {
        ...state,
        blockCardStatus: action.payload.data,
        blockCardError: null,
        isFetching: false
      }
    }
    case `${BLOCK_CARD}_FAILED`: {
      return {
        ...state,
        blockCardStatus: null,
        blockCardError: action.payload.data,
        isFetching: false
      }
    }

    case `${APPLY_CARD}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${APPLY_CARD}_SUCCESS`: {
      return {
        ...state,
        applyCard: action.payload?.data,
        applyCardError: action.payload?.response?.data,
        isFetching: false
      }
    }
    case `${APPLY_CARD}_FAILED`: {
      return {
        ...state,
        applyCard: null,
        applyCardError: action.payload?.response?.data,
        isFetching: false
      }
    }
// -------------------------- DOCS VIEW -------------------------------//


    case `${GET_CARD_STEATMENTS_VIEW}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${GET_CARD_STEATMENTS_VIEW}_SUCCESS`: {
      const { data, response } = action.payload;
      // openPDFViewByData(response ? response.data : data)
      return {
        ...state,
        statementsData: response ? response.data : data,
        isFetching: false
      }
    }
    case `${GET_CARD_STEATMENTS_VIEW}_FAILED`: {
      const { data } = action.payload;
      return {
        ...state,
        statementsData: data,
        isFetching: false
      }
    }
    case `${GET_CARD_INVOICES_VIEW}_REQUEST`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${GET_CARD_INVOICES_VIEW}_SUCCESS`: {
      const { data, response } = action.payload;
      // openPDFViewByData(data)
      return {
        ...state,
        invoicesData: response ? response.data : data,
        isFetching: false
      }
    }
    case `${GET_CARD_INVOICES_VIEW}_FAILED`: {
      const { data } = action.payload;
      return {
        ...state,
        invoicesData: data,
        isFetching: false
      }
    }
    case RESET_DATA: {
      if (action.payload) {
        return {
          ...initState,
        }
      } else {
        return {
          ...state,
          statementsData: null,
          invoicesData: null,
        }
      }
    }
    case RESET_CARDS_ERRORS: {
      return {
        ...state,
        error: null,
        manageCardNameError: null,
        viewPinError: null,
        changePinError: null,
        lostCardError: null,
        blockCardError: null,
        manageCardNameStatus: null,
        viewPinStatus: null,
        changePinStatus: null,
        lostCardStatus: null,
        blockCardStatus: null,
        applyCard: null
      }
    }
    default:
      return state
  }
}