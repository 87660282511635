import React, { useState } from 'react'
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  NavLink,
  Link
} from "react-router-dom";
import Logo from '../../assets/images/logo.png'
import { setToken } from '../../redux/actions/authActions';
import { resetStuff } from '../../redux/actions/stuffActions';
import { Button } from '../Button';
import './header.css'
import { ReactComponent as LogoMobile } from '../../assets/images/insignia_logo.svg';
import { ReactComponent as Profile } from '../../assets/images/user-profile-rounded.svg';
import { ReactComponent as ProfileMobile } from '../../assets/images/profile_ico.svg';
// Icons
import * as FiIcons from 'react-icons/fi';
import * as AiIcons from 'react-icons/ai';
import TermsCondiotionsIcon from '../../assets/images/Insignia_Annual_Report.svg';
import TariffPlanIcon from '../../assets/images/letters-icon.svg';
import menu_products from '../../assets/images/menu/menu_products.svg';
import menu_eligibility from '../../assets/images/menu/menu_letters.svg';
import menu_terms from '../../assets/images/menu/menu_terms.svg';
import menu_tariffs from '../../assets/images/menu/menu_tariffs.svg';
import menu_cards from '../../assets/images/menu/menu_cards.svg';
import menu_letters from '../../assets/images/menu/menu_letters.svg';
import menu_ica from '../../assets/images/menu/menu_ica.svg';
import menu_contacts from '../../assets/images/menu/menu_contacts.svg';
import menu_settings from '../../assets/images/menu/menu_settings.svg';
import menu_logout from '../../assets/images/menu/menu_logout.svg';

const mapStateToProps = state => ({
  isLoggedIn: state.auth.userData && !state.auth.userData.secondFactorEnabled,
  token: state.stuff.token || state.auth?.userData?.token || false,
  lettersCount: state.letters.lettersCount,
})

const links = [
  {
    id: 1,
    header: 'Insignia Products',
    link: '/products',
    icon: TermsCondiotionsIcon,
    iconMobile: menu_products,
  },
  {
    id: 2,
    header: 'Eligibility Form',
    link: '/Form',
    icon: TermsCondiotionsIcon,
    iconMobile: menu_eligibility,
  },
  {
    id: 3,
    header: 'Tariff Plan',
    link: '/tariff',
    icon: TariffPlanIcon,
    iconMobile: menu_tariffs,
  },
  {
    id: 4,
    header: 'Terms & Conditions',
    link: '/conditionsCard',
    icon: TermsCondiotionsIcon,
    iconMobile: menu_terms,
  }
]

export const Header = connect(mapStateToProps)(({token, lettersCount, isLoggedIn, dispatch}) => {
  const [menu, setMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);

  const showMenu = () => setMenu(!menu);
  const showProfileMenu = () => setProfileMenu(!profileMenu);

  const logout = () => {
    dispatch(
      setToken()
    )
    dispatch(
      resetStuff()
    )
  }
  return (
    <header className="header">
      <div className='logo-wrapper'>
        <NavLink to='/'>
          <img src={Logo} alt='logo' width='100%' height='100%' className='logo show-desktop' />
          <LogoMobile className="show-mobile" />
        </NavLink>
      </div>

      {isLoggedIn ? <React.Fragment>
        <div className='nav-menu'>
          <NavLink to='/cards' activeClassName='active-link'>
            Cards
          </NavLink>

          <NavLink to='/Letters' activeClassName='active-link'>
            Letters
            {lettersCount ? <span className="letters-count">{lettersCount}</span> : null}
          </NavLink>

          <NavLink to='/contact' activeClassName='active-link'>
            Contact
          </NavLink>

          <a href='https://ica-online.com' target='_blank' >
            ICA
          </a>
        </div>

        <div className='buttons'>
          <button
            onClick={showProfileMenu}
            className="profile_opener_button"
          >
            <Profile
              width={27}
              height={27}
              className='user-profile-icon show-desktop'
            />
            <ProfileMobile
              width={27}
              height={27}
              className='user-profile-icon show-mobile'
            />
          </button>

          <button
            onClick={showMenu}
            className="burger_menu_button"
          >
            <FiIcons.FiMenu />
          </button>

          <NavLink
            to='/profile'
            className="profile-button__desktop"
            activeClassName='active-link'
          >
            <div style={{height: '100%', position: 'relative', alignItems: 'center', display: 'flex'}}>
              <Profile width={27} height={27} className='user-profile-icon' />
            </div>
          </NavLink>

          <NavLink className="button-logout" to='/login' onClick={logout}>
            <Button text={token ? 'Log Out' : 'Log In'} />
          </NavLink>
        </div>

        {/* PROFILE MENU */}
        <div className={profileMenu ? "side_menu side_menu_profile active" : "side_menu side_menu_profile"}>
          <button onClick={showProfileMenu} className="burger_menu_button active">
            <AiIcons.AiOutlineClose />
          </button>

          <div className="side-menu-title">Profile</div>

          <div className='nav-menu nav-menu-mobile'>
            <NavLink to='/profile' activeClassName='active-link'>
              <img className="menu-icon-mobile" src={menu_settings} alt="Cards" />
              Settings
            </NavLink>

            <NavLink to='/login' onClick={logout} className="menu-item-red">
              <img className="menu-icon-mobile" src={menu_logout} alt="Cards" />
              Logout
            </NavLink>
          </div>
        </div>

        {/* MENU */}
        <div className={menu ? "side_menu active" : "side_menu"}>
          <button onClick={showMenu} className="burger_menu_button active">
            <AiIcons.AiOutlineClose />
          </button>

          <div className="side-menu-title">Menu</div>

          <div className='nav-menu nav-menu-mobile'>
            <NavLink to='/cards' activeClassName='active-link'>
              <img className="menu-icon-mobile" src={menu_cards} alt="Cards" />
              Cards
            </NavLink>

            <NavLink to='/Letters' activeClassName='active-link'>
              <img className="menu-icon-mobile" src={menu_letters} alt="Letters" />
              Letters
              {lettersCount ? <span className="letters-count">{lettersCount}</span> : null}
            </NavLink>

            <NavLink to='/contact' activeClassName='active-link'>
              <img className="menu-icon-mobile" src={menu_contacts} alt="ICA" />
              Contact
            </NavLink>

            <a href='https://ica-online.com' target='_blank' >
              <img className="menu-icon-mobile" src={menu_ica} alt="Contact" />
              ICA
            </a>
          </div>

          <ul>
            {
              links.map(item => (
                <li key={item.id}>
                  <NavLink to={item.link} activeClassName='active-link'>
                    <img className="menu-icon-desktop" src={item.icon} alt="Menu_links" />
                    <img className="menu-icon-mobile" src={item.iconMobile} alt="Menu_links" />
                    <span>{item.header}</span>
                  </NavLink>
                </li>
              ))
            }
          </ul>
        </div>
      </React.Fragment> : null}
    </header>
  )
})
