import Axios from "axios"
import { refresh } from ".";

const version = 1

export  const apiRoutes = {
    // AUTH
    login: `/api/v${version}/Auth/login`,
    verifyCode: `/api/v${version}/Auth/verifyCode`,
    refresh: `/api/v${version}/Auth/refresh`,
    register: `/api/v${version}/Auth/register`,
    recoverPassword: `/api/v${version}/Auth/recoverPassword`,

    // CARDS
    cardsProducts: `/api/v${version}/Cards/products/`,
    cardsAll: `/api/v${version}/Cards/all/`,
    cardsLatest: `/api/v${version}/Cards/latest/`,
    invoices: `/api/v${version}/Cards/invoices/`,
    invoicesDownload: `/api/v${version}/Cards/invoices/download/`,
    invoicesPrint: `/api/v${version}/Cards/invoices/print/`,
    statements: `/api/v${version}/Cards/statements/`,
    download: `/api/v${version}/Cards/statements/download/`,
    print: `/api/v${version}/Cards/statements/print/`,
    apply: `/api/v${version}/Cards/apply`,
    name: `/api/v${version}/Cards/manage/name/`,
    viewPin: `/api/v${version}/Cards/manage​/viewPin​/`,
    changePin: `/api/v${version}/Cards/manage/changePin/`,
    lost: `/api/v${version}/Cards/manage/lost/`,
    block: `/api/v${version}/Cards/manage/block/`,
    allInvoices: `/api/v${version}/Cards/allinvoices/`,
    allStatements: `/api/v${version}/Cards/allstatements/`,

    // CONTENT
    feed: `/api/v${version}/Content/feed/`,
    services: `/api/v${version}/Content/services/`,
    contact: `/api/v${version}/Content/text/contact/`,
    tariff: `/api/v${version}/Content/text/tariff/`,
    siteTC: `/api/v${version}/Content/text/siteTC/`,
    cardsTC: `/api/v${version}/Content/text/cardsTC/`,
    cookies: `/api/v${version}/Content/text/cookies/`,
    privacy: `/api/v${version}/Content/text/privacy/`,

    // LETTERS
    letters: `/api/v${version}/Letters/`,
    lettersContent: `/api/v${version}/Letters/content/`,
    lettersDownload: `/api/v${version}/Letters/download`,
    lettersPrint: `/api/v${version}/Letters/print`,
    lettersArchive: `/api/v${version}/Letters/archive/`,
    lettersCount: `/api/v${version}/Letters/unread_count`,

    // PROFILE
    profile: `/api/v${version}/Profile`,
    changePassword: `/api/v${version}/Profile/changePassword`,

    // VIEW_DOCS
    cardDocView: `/api/v${version}/Cards/invoices/view/`,
    cardStatementView: `/api/v${version}/Cards/statements/view/`
}

//export const baseURL = 'https://iobapi-dev.crm555.com';
export const baseURL = 'https://api.insigniaonline.info';

/*let refreshCounter = 1
Axios.interceptors.response.use(
    (response) => {
        console.log('interceptors response')
        if (refreshCounter !== 0) {
            // refreshCounter = 0
        }
        return response
    },
    (error) => {
        //console.log('interceptors error', error.response?.status)
        //console.log('refreshCounter', refreshCounter);
    const route = window.location.pathname
    // console.log('route', route);
    if (error.response?.status === 401 && refreshCounter <= 1) {// && route !== '/login' && route !== '/logincode' && route !== '/profile' && route !== '/' && route !== '/Letters') {
        //console.log('refreshing');
        refreshCounter++
        const stuff = JSON.parse(localStorage.getItem('stuff'))
        //console.log('stuff', stuff);
        Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.refreshToken}`
        refresh({refresh_token: stuff?.refreshToken, token: stuff?.refreshToken})
            .then(r => {
                if (r.response.status === 401) {
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.token}`
                    window.location.replace('/login')
                } else {
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${r.data.token}`
                    localStorage.setItem('stuff', JSON.stringify({...r.data}));
                }
            })
            .catch(e => {
                Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.token}`
            })
    }
    if (route === '/Letters') {
        const stuff = JSON.parse(localStorage.getItem('stuff'))
        Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.refreshToken}`
    }
    return error
});*/

export const API = {
    get: ({url, params, ...rest}) => callMethod({
        method: 'GET',
        url,
        params,
        baseURL,
        ...rest
    }),
    post: ({url, data, params}) => callMethod({
        method: 'POST',
        url,
        data,
        params,
        baseURL
    }),
    put: ({url, data, params}) => callMethod({
        method: 'PUT',
        url,
        data,
        params,
        baseURL
    }),
    delete: ({url, params, data}) => callMethod({
        method: 'DELETE',
        url,
        params,
        data,
        baseURL
    }),
    plainPost: ({url, data, params}) => Axios({
        method: 'POST',
        url,
        data,
        params,
        baseURL
    })
}

API._refresh = async function() {
    let stuff = null;
    try {
      stuff = JSON.parse(localStorage.getItem('stuff'));
    } catch (err) {}
    if (stuff) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${stuff?.refreshToken}`;
        try {
            const result = await refresh({refresh_token: stuff?.refreshToken, token: stuff?.refreshToken});
            Axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;
            API._refreshPromise = null;
            localStorage.setItem('stuff', JSON.stringify({...result.data}));
        } catch (err) {
            API._refreshPromise = null;
            delete Axios.defaults.headers.common['Authorization'];
            throw err;
        }
    } else {
        API._refreshPromise = null;
        delete Axios.defaults.headers.common['Authorization'];
        throw new Error('No stuff');
    }
};

API.refresh = function() {
  if (!API._refreshPromise) {
    API._refreshPromise = API._refresh();
  }
  return API._refreshPromise;
};

async function callMethod(params) {
    let err = null;
    try {
        const result = await Axios(params);
        return result;
    } catch (err) {
        if (err.response?.status === 401) {
            try {
                await API.refresh();
                const result = await Axios(params);
                return result;
            } catch (err) {
                window.location.replace('/login');
            }
        } else {
            throw err;
        }
    }
}