import React, { useEffect, useState, useRef } from 'react'
import { screenHOC } from '../HOC/screenHOC'
import classNames from 'classnames/bind'
import classes from './letters.scss'
import { Button } from '../../components/Button'
import moment from 'moment'
import { connect } from 'react-redux'
import { deleteLetterAction, getLatterContentAction, getLatterDownloadAction, getLettersAction, letterToArchiveAction, resetReadedLetterAction } from '../../redux/actions/lettersActions'
import MessagePopUp from '../../components/MessagePopUp'
import { Modal } from '../../components/Modal'
import {ReactComponent as CloseIcon} from '../../assets/images/close-black.svg';
import { Preloader } from '../../components/Preloader'
import Axios from 'axios'

const cx = classNames.bind(classes)

const lettersMenuItems = [
  {
    icon: '../../assets/images/letters-icon.svg',
    iconMobile: '../../assets/images/letters-icon_mobile.svg',
    text: 'Letters',
    type: null,
    counter: true
  },
  // {
  //   icon: '../../assets/images/star.svg',
  //   text: 'Starred',
  //   type: 'favorite'
  // },
  {
    icon: '../../assets/images/attention-icon.svg',
    iconMobile: '../../assets/images/attention-icon_mobile.svg',
    text: 'Important',
    type: 'important'
  },
  {
    icon: '../../assets/images/archive-icon.svg',
    iconMobile: '../../assets/images/archive-icon_mobile.svg',
    text: 'Archive',
    type: 'archive'
  },
  {
    icon: '../../assets/images/send-message-icon.svg',
    iconMobile: '../../assets/images/send-message-icon_mobile.svg',
    text: 'Sent',
    type: 'outgoing'
  },
]

const Letters = ({dispatch, lettersArr, lettersArchiveSuccess, lettersDeleteSuccess, isFetching}) => {
    const [letters, setLetters] = useState(lettersArr)
    const [letterFilter, setLetterFilter] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [deletedId, setDeletedId] = useState(false)
    const [openLetter, setOpenLetter] = useState(false)
    const [tabs, setTabs] = useState([])

    const refCompose = useRef(null);

    // Get letters
    useEffect(() => {
      dispatch(
        getLettersAction()
      )
    }, [])

    // useEffect(() => {
    //   Axios.defaults.headers.common['Authorization'] = ''
    // })

    // End Get letters

    useEffect(() => {
      if (lettersArr) {
        if (letterFilter) {
          // eslint-disable-next-line default-case
          switch (letterFilter) {
            case 'important':
              setLetters(lettersArr.filter(i => i[letterFilter] && !i.deleted && !i.archive))
              break;
            case 'archive':
              setLetters(lettersArr.filter(i => i[letterFilter] && !i.deleted))
              break;
            case 'outgoing':
              setLetters(lettersArr.filter(i => i[letterFilter] && !i.archive))
              break;
          }

        } else {
          setLetters(lettersArr.filter(i => !i.deleted && !i.archive))
        }
      }
    }, [letterFilter, lettersArr])

    useEffect(() => {
      if (lettersArchiveSuccess || lettersDeleteSuccess) {
        dispatch(
          resetReadedLetterAction()
        )
        dispatch(
          getLettersAction()
        )
      }
    }, [lettersArchiveSuccess, lettersDeleteSuccess])

    const handleFilter = (type, context) => () => {
      setLetterFilter(type)
    }

    const handleFavorite = (item, index, e) => {
      e.stopPropagation();
      let newLetters = [...letters]
      newLetters[index].isFavorite = !newLetters[index].isFavorite
      setLetters(newLetters)
    }

    const handleImportant = (item, index, e) => {
      e.stopPropagation();
      let newLetters = [...letters]
      newLetters[index].isImportant = !newLetters[index].isImportant
      setLetters(newLetters)
    }

    const handleArchive = (item, index, e) => {
      e.stopPropagation();
      dispatch(
        letterToArchiveAction(item.id)
      )
    }

    const handleDelete = (item, index, e)  => {
      e && e.stopPropagation();
      setDeletedId(item.id)
      setModalDelete(true)
    }

    const handleReadContent = (id, e, letter) => {
      e.stopPropagation();
      setOpenLetter(letter)
      if (letter?.type === 'Message') {
        dispatch(
          getLatterContentAction(id)
        )
      } else if (letter) {
        dispatch(
          getLatterDownloadAction(id)
        )
      }
    }

    const minimizeLetter = (letter) => {
      setTabs(tabs.concat([letter]))
    }

    const deleteTab = (index) => {
      const newTabs = [...tabs]
      newTabs.splice(index, 1)
      setTabs(newTabs)
    }

    const handleOpenTab = (id, e, tab, key) => {
      deleteTab(key)
      if (!id) refCompose.current.click();
      else handleReadContent(id, e, tab);
    }

    const renderLetter = (letter, key) => {
      return <tr className={cx('hovered-row')} onClick={e => handleReadContent(letter.id, e, letter)} key={key}>
              <td className={cx('first-col')}>
                <div onClick={(e) => handleImportant(letter, key, e)} className='hovered-icon'>
                  {
                    letter.important || letters[key].isImportant
                      ? <img src='../../assets/images/attention-icon.svg' alt='' />
                      : null
                  }
                </div>
              </td>
              <td>{moment(letter.date).format('DD MMM YYYY')}</td>
              <td><span>{letter.subject}</span></td>
              <td><span>{moment(letter.date).format('h:mm a')}</span></td>
              <td >
                <div className={cx('last-col')}>
                  {
                    letters[key].archive
                      ? null
                      : <div onClick={e => handleArchive(letter, key, e)} className='hovered-icon'>
                      <img className="show-mobile" src='../../assets/images/archive-colored-icon_mobile.svg' alt='' />
                      <img className="show-desktop" src='../../assets/images/archive-colored-icon.svg' alt='' />
                      <p>Archive</p>
                    </div>
                  }
                  <div onClick={e => handleDelete(letter, key, e)} className='hovered-icon'>
                    <img className="show-mobile" src='../../assets/images/trash-icon_mobile.svg' alt='' />
                    <img className="show-desktop" src='../../assets/images/trash-icon.svg' alt='' />
                    <p>Delete</p>
                  </div>
                </div>
              </td>
            </tr>
    }

    const renderTab = (tab, key) => <div key={key} className='letter-tab' onClick={e => handleOpenTab(tab?.id, e, tab, key)}>
      <div className='letter-tab-title'>
        {tab?.subject || 'new message'}
      </div>
      <div className='letter-tab-buttonBlock'>
        <div className='letter-tab-maximize' onClick={e => handleOpenTab(tab?.id, e, tab, key)}>
          +
        </div>
        <span className='letter-tab-close' onClick={() => deleteTab(key)}>
          <CloseIcon />
        </span>
      </div>
    </div>

    if (lettersArr && letters) {
      return (
        <div className={cx('letters')}>
          <div className={cx('letters-menu')}>
            <div className={cx('button-wrap')}>
              <MessagePopUp
                onClose={() => {
                  dispatch(
                    getLettersAction()
                  )
                }}
                minimizeLetter={minimizeLetter}
                handleDelete={handleDelete}
                handleArchive={handleArchive}
                openedLetter={openLetter}
                setOpenLetter={setOpenLetter}
                >
                <div className='button' style ={{width: '100%'}} refControl={refCompose}>
                  <span className='button-big-text'>+</span> <span>Compose</span>
                </div>
              </MessagePopUp>
            </div>
            <div className={cx('letters-menu-items')}>
              {
                lettersMenuItems.map((item, key) => {
                  let unreadMessages = item.counter && lettersArr.filter(i => !i.archive && !i.read ).length
                  return (
                    <div key={key} className={cx('letters-menu-item', item.type === letterFilter && 'active')} onClick={handleFilter(item.type)}>
                      <img className="show-desktop" src={item.icon} alt='' />
                      <img className="show-mobile" src={item.iconMobile} alt='' />
                      <p>{item.text}</p>
                      {
                        unreadMessages
                          ? <span className='letters-counter'>{unreadMessages}</span>
                          : null
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={cx('letters-table')}>
              <table style={letters.length === 0 ? {height: '250px'} : {height: 'auto'}}>
                <thead>
                  <tr>
                    <td style={{width: '10%'}}/>
                    <td>{'Date'.toUpperCase()}</td>
                    <td>{'Subject'.toUpperCase()}</td>
                    <td>{'Time'.toUpperCase()}</td>
                    <td style={{width: '1%'}}/>
                  </tr>
                </thead>
                <tbody>
                  {
                    letters.length
                      ? letters.map((letter, key) => renderLetter(letter, key))
                      : <tr style={{width: '100%', textAlign: 'center'}}>
                          <td className="no-letters" colSpan={5}>Letters were not found</td>
                        </tr>
                  }
                </tbody>
              </table>
          </div>

          <Modal
            hideButton={true}
            modalOpen={modalDelete}
            title='Delete Permanently'
            content={
                <div className="letter-modal-delete" style={{
                  
                }}>
                    Please confirm that you want to permanently delete this letter.
                </div>
            }
            onClose={() => {
              setDeletedId(null)
              setModalDelete(!modalDelete)
            }}
            buttons={[
              {
                text: 'Cancel',
                onClick: () => setModalDelete(!modalDelete)
              },
              {
                  onClick: () => {
                    dispatch(
                      deleteLetterAction(deletedId)
                    )
                    setModalDelete(!modalDelete)
                  }
                      ,
                  text: 'Yes, Delete',
              },
            ]}
          />
          <div className='letter-tabs'>
            {
              tabs.length ? tabs.map(renderTab) : null
            }
          </div>
          {
            isFetching
              ? <Preloader preloaderOpacity={'50%'} />
              : null
          }
        </div>
      )
    } else {
      return <Preloader />
    }
  }

const mapStateToProps = state => ({
  lettersArr: state.letters.letters,
  lettersArchiveSuccess: state.letters.letterToArchiveSuccess,
  lettersDeleteSuccess: state.letters.lettersDeleteSuccess,
  isFetching: state.letters.isFetching
})
export default connect(mapStateToProps)(screenHOC(Letters))
