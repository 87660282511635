import React from 'react'
import Loader from 'react-loader-spinner'

import './Preloader.css';

// style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: preloaderOpacity || 0}}

export const Preloader = ({preloaderOpacity}) => {
  return (
    <div className="preloader" style={{opacity: preloaderOpacity || 0}}>
      <Loader
        style={{marginLeft: '10px', height: '25px'}}
        visible
        type="Oval"
        color="#d7c29a"
        height={25}
        width={25}
      />
    </div>
  )
}
