import React, {useEffect, useState, useRef} from 'react';
import './styles.css';
import mail from '../../assets/images/contact/mail.svg';
import close from '../../assets/images/contact/close.svg';
import deleteIcon from '../../assets/images/contact/delete.svg';
import { connect } from 'react-redux';
import { postLettersAction, resetReadedLetterAction } from '../../redux/actions/lettersActions';
import { Modal } from '../Modal';
import Loader from 'react-loader-spinner';
import Download from '../../assets/images/download.svg';
import Print from '../../assets/images/print.svg';
import { downloadPDFViewByData, printPDFViewByData } from '../../redux/helpers';
import { resetData } from '../../redux/actions/cardsActions';
import scroll from '../../utils/scroll';

const MessagePopUp = ({dispatch, postSuccess, letterRead, letterDownload, children, onClose, handleDelete, handleArchive, openedLetter, setOpenLetter, minimizeLetter, pdfDoc, pdfType}) => {
    const [popupShow, setPopupShow] = useState(false);
    const [email, setEmail] = useState('cs.unit@insignia.com');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [pdf, setPdf] = useState('');
    const [groupId, setGroupId] = useState(false)
    const [modalSendSuccess, setModalSendSuccess] = useState(false)

    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (popupShow) scroll.disable();
            else scroll.enable();
        }
        return () => {
            if (popupShow) scroll.enable();
        }
    }, [popupShow]);


    useEffect(() => {
        if (postSuccess) {
            handleCloseClick()
            setTimeout(
                () => {
                    sendModalSuccess()
                }
            )
        }
    }, [postSuccess])

    useEffect(() => {
        if (!!letterRead) {
            setSubject(letterRead.subject)
            setText(letterRead.body)
            setPopupShow(true)
        }
    }, [letterRead])

    useEffect(() => {
        if (openedLetter && openedLetter.id !== groupId) {
            setGroupId(openedLetter.id)
        }
        if (openedLetter) {
            setSubject(!openedLetter?.outgoing ?  `RE: ${openedLetter.subject}` : openedLetter.subject)
        }
    }, [openedLetter])

    useEffect(() => {
        if (!!letterDownload) {
            setPdf(letterDownload)
            setPopupShow(true)
        }
    }, [letterDownload])

    useEffect(() => {
        if (!!pdfDoc) {
            setPdf(pdfDoc)
            setPopupShow(true)
        }
    }, [pdfDoc])

    const handleFormSubmit = e => {
        e.preventDefault();
        if (letterRead) {
            handleCloseClick()
        } else {
            dispatch(
                postLettersAction({
                    groupId: groupId,
                    subject: subject,
                    body: text
                })
            )
        }
    };

    const handleCloseClick = () => {
        setPopupShow(false);
        setSubject('');
        setText('');
        setPdf(null)
        setOpenLetter && setOpenLetter(null)
        dispatch(
            resetReadedLetterAction()
        )
        onClose && onClose()
    };

    const handleClose = () => {
        setPopupShow(false);
        setSubject('');
        setText('');
        setPdf(null)
        onClose && onClose()
    };

    const sendModalSuccess = () => {
        setModalSendSuccess(true)
        setOpenLetter && setOpenLetter(null)
    }

    const handleMinimizeClick = () => {
        minimizeLetter(openedLetter)
        setPopupShow(false);
        setSubject('');
        setText('');
        setPdf(null)
        setOpenLetter && setOpenLetter(null)
    };

    const handleDeleteClick = () => {
        setSubject('');
        setPopupShow(false)
        handleDelete && handleDelete(letterRead)
        setOpenLetter && setOpenLetter(null)
    };

    const handleReply = (e) => {
        e.preventDefault()
        setText('')
        setOpenLetter && setOpenLetter(null)
        setPdf(null)
    };
    return (
        <div>
            {
                children
                    ?   <div onClick={() => setPopupShow(true)}>
                        {children}
                    </div>
                    :   <div
                    className={ `popup-open-button ${!popupShow && 'active'}` }
                    onClick={ () => setPopupShow(true) }
                >
                    <img src={ mail } alt="open-icon" className="popup-open-button-icon"/>
                </div>
            }
            <div className={ `popup-wrapper ${popupShow && 'active'}` } style={pdfDoc && {justifyContent: 'center', alignItems: 'center', display: 'flex'}} >
                <div className={ `popup-block ${pdfDoc && 'popup-block-center'} ${popupShow && 'active'}` } style={{minHeight: pdf ? 551 : 'auto'}}>
                    <div className="popup-header">
                        <div className="popup-header-text">
                            {
                                openedLetter
                                    ?   openedLetter.subject
                                    :   pdfDoc
                                        ?   pdfType
                                        :   'New message'
                            }
                        </div>
                        <div className="popup-header-buttons">
                            {
                                !pdfDoc &&
                                    <div className="popup-header-button" onClick={ () => handleMinimizeClick() }>
                                        <div className="popup-header-button-minimize" />
                                    </div>
                            }
                            <div className="popup-header-button" onClick={ () => pdfDoc ? handleClose() : handleCloseClick() }>
                                <img src={ close } alt="header-button" className="popup-header-button-icon" />
                            </div>
                        </div>
                    </div>
                    <div className="popup-content" style={{minHeight: pdf ? 551 : 'auto'}}>
                        <form onSubmit={ handleFormSubmit }>
                            {
                                !pdfDoc &&
                                    <>
                                        {
                                            openedLetter?.outgoing || !openedLetter
                                                ?   <div className="popup-input-block">
                                                        <label htmlFor="To" className="popup-input-label">To</label>
                                                        <input
                                                            id="To"
                                                            type="email"
                                                            onChange={ e => setEmail(e.target.value) }
                                                            value={ email }
                                                            className="popup-input"
                                                            required
                                                            readOnly
                                                        />
                                                    </div>
                                                :   <div className="popup-input-block">
                                                        <label htmlFor="From" className="popup-input-label">From</label>
                                                        <input
                                                            id="From"
                                                            type="email"
                                                            onChange={ e => setEmail(e.target.value) }
                                                            value={ email }
                                                            className="popup-input"
                                                            required
                                                            readOnly
                                                        />
                                                    </div>
                                        }
                                    </>
                            }
                            {
                                !pdfDoc &&
                                    <div className="popup-input-block">
                                        <label htmlFor="subject" className="popup-input-label">Subject</label>
                                        <input
                                            id="subject"
                                            type="text"
                                            onChange={ e => setSubject(e.target.value) }
                                            value={ subject }
                                            className="popup-input"
                                            required
                                            readOnly={!!openedLetter || (openedLetter && !openedLetter.outgoing)}
                                        />
                                    </div>
                            }
                            {
                                pdf
                                ?   <iframe title='pdf' src={URL.createObjectURL(pdf)} width={'100%'} height={pdfDoc ? 550 : 551-129}>
                                    <Loader />
                                </iframe>
                                :   <div className="popup-textarea-block">
                                        <textarea
                                            className="popup-textarea"
                                            onChange={ e => setText(e.target.value) }
                                            value={ text || '' }
                                            placeholder={'Write your text here ...'}
                                            readOnly={!!openedLetter || (openedLetter && !openedLetter.outgoing)}
                                        />
                                    </div>
                            }
                            <div
                                className={`popup-buttons-block ${openedLetter ? 'popup-buttons-block-rows' : ''}`}>
                                {
                                    openedLetter && !openedLetter?.outgoing && !pdfDoc
                                    ?   <button className="popup-submit-button button" onClick={handleReply}>
                                            <span>
                                                Reply
                                            </span>
                                        </button>
                                    :   !openedLetter?.outgoing && !pdfDoc
                                            ? <button type="submit" className="popup-submit-button button">
                                                    <span>
                                                    Send
                                                    </span>
                                            </button>
                                            :   <div />


                                }
                                {
                                    pdfDoc
                                    ?   <button className="popup-submit-button button" onClick={handleClose}>
                                            <span>
                                                Ok
                                            </span>
                                        </button>
                                    :   null
                                }
                                {
                                    openedLetter
                                        ?   <div className="letter-buttons">
                                                {
                                                    pdf
                                                    ?   
                                                        <div
                                                            onClick={() => downloadPDFViewByData(pdf, 'lettersPdf', true)}
                                                            className="popup-delete-button letter-button-download hovered-icon">
                                                            <img src={ Download } alt="delete-icon" className="popup-delete-button-icon" />
                                                            <span className="popup-delete-button-text">Download</span>
                                                        </div>
                                                    :   null
                                                }
                                                {
                                                    pdf
                                                    ?   
                                                        <div
                                                            onClick={() => printPDFViewByData(pdf, true)}
                                                            className="popup-delete-button letter-button-print hovered-icon">
                                                            <img src={ Print } alt="delete-icon" className="popup-delete-button-icon" />
                                                            <span className="popup-delete-button-text">Print</span>
                                                        </div>
                                                    :   null
                                                }

                                                <div
                                                    onClick={e => {
                                                        handleArchive(openedLetter, null, e)
                                                        handleCloseClick()
                                                        }}
                                                    className="popup-delete-button letter-button-archive hovered-icon"
                                                >
                                                    <img src={ Download } alt="delete-icon" className="popup-delete-button-icon" />
                                                    <span className="popup-delete-button-text">Archive</span>
                                                </div>

                                                <div className="popup-delete-button letter-button-delete hovered-icon" onClick={ () => handleDeleteClick() }>
                                                    <img src={ deleteIcon } alt="delete-icon" className="popup-delete-button-icon" />
                                                    <span className="popup-delete-button-text">Delete</span>
                                                </div>
                                            </div>
                                        :   null
                                }

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal
                hideButton={true}
                modalOpen={modalSendSuccess}
                title='Sent Successfully'
                content={
                    <div style={{
                    width: '307px',
                    height: '84px',
                    margin: '18px auto 14px auto',
                    fontFamily: 'ProximaNova',
                    fontSize: '18px',
                    fontWeight: '500',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    color: '#88888b',
                    }}>
                        We have received your correspondence. A member of our team will be in touch with you shortly.
                    </div>
                }
                onClose={() => {
                  setModalSendSuccess(false)
                  handleCloseClick()
                  onClose && onClose()
                }}
                buttons={[
                    {
                        onClick: () => {
                            setModalSendSuccess(false)
                        }
                            ,
                        text: 'Ok',
                    },
                ]}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    letters: state.letters,
    postSuccess: state.letters.postSuccess,
    letterRead: state.letters.letterRead,
    letterDownload: state.letters.letterDownload
})

export default connect(mapStateToProps)(MessagePopUp);