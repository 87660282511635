import {
  GET_LETTERS,
  POST_LETTERS,
  GET_LATTER_CONTENT,
  GET_LATTER_DOWNLOAD,
  DELETE_LETTER,
  LETTER_TO_ARCHIVE,
  RESET_READED_LETTER,
  GET_LATTER_PRINT,
  GET_LETTERS_COUNT,
  SET_LETTERS_COUNT
} from "../constants";

export const getLettersAction = () => ({
  type: `${GET_LETTERS}_REQUEST`,
});

export const postLettersAction = (payload) => ({
  type: `${POST_LETTERS}_REQUEST`,
  payload
});

export const getLatterContentAction = (payload) => ({
  type: `${GET_LATTER_CONTENT}_REQUEST`,
  payload
});

export const resetReadedLetterAction = (payload) => ({
  type: `${RESET_READED_LETTER}`,
  payload
});

export const getLatterDownloadAction= (id) => ({
  type: `${GET_LATTER_DOWNLOAD}_REQUEST`,
  payload: id
});

export const getLatterPrintAction= (id) => ({
  type: `${GET_LATTER_PRINT}_REQUEST`,
  payload: id
});

export const deleteLetterAction = (payload) => ({
  type: `${DELETE_LETTER}_REQUEST`,
  payload
});

export const letterToArchiveAction = (payload) => ({
  type: `${LETTER_TO_ARCHIVE}_REQUEST`,
  payload
});

export const getLettersCount = () => ({
  type: `${GET_LETTERS_COUNT}`
});

export const setLettersCount = (payload) => ({
  type: `${SET_LETTERS_COUNT}`,
  payload
});
