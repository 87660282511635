import {
  GET_ALL_STATEMENS_CARD,
  GET_ALL_INVOICES_CARD,
  GET_CARDS_ALL,
  GET_CARDS_LATEST,
  GET_INVOICES_CARD,
  GET_INVOICES_DOWNLOAD,
  GET_INVOICES_PRINT,
  GET_INVOICES_STATEMENS,
  GET_INVOICES_STATEMENS_DOWNLOAD,
  GET_INVOICES_STATEMENS_PRINT,
  APPLY_CARD,
  MANAGE_CARD_NAME,
  VIEW_PIN,
  CHANGE_PIN,
  LOST_CARD,
  BLOCK_CARD,
  GET_CARD_STEATMENTS_VIEW,
  GET_CARD_INVOICES_VIEW,
  GET_CARDS_PRODUCTS,
  RESET_DATA,
  RESET_CARDS_ERRORS,
} from "../constants";


export const getCardsProducts = () => ({
  type: `${GET_CARDS_PRODUCTS}_REQUEST`,
});

export const getCardsAll = () => ({
  type: `${GET_CARDS_ALL}_REQUEST`,
});

export const getCardsLatest = () => ({
  type: `${GET_CARDS_LATEST}_REQUEST`,
});

export const getInvoicesCardAction = (payload) => ({
  type: `${GET_INVOICES_CARD}_REQUEST`,
  payload
});

export const getAllInvoicesCardAction = (payload) => ({
  type: `${GET_ALL_INVOICES_CARD}_REQUEST`,
  payload
});

export const getInvoicesDownloadAction = (id, name, reset) => ({
  type: `${GET_INVOICES_DOWNLOAD}_REQUEST`,
  payload: {
    id,
    name,
    reset
  }
});

export const getInvoicesPrintAction = (payload) => ({
  type: `${GET_INVOICES_PRINT}_REQUEST`,
  payload
});

export const getInvoicesStatemensAction = (payload) => ({
  type: `${GET_INVOICES_STATEMENS}_REQUEST`,
  payload
});

export const getAllStatemensCardAction = (payload) => ({
  type: `${GET_ALL_STATEMENS_CARD}_REQUEST`,
  payload
});

export const getInvoicesStatemensDownloadAction = (id, name, reset) => ({
  type: `${GET_INVOICES_STATEMENS_DOWNLOAD}_REQUEST`,
  payload: {
    id,
    name,
    reset
  }
});

export const getInvoicesStatemensPrintAction = (payload) => ({
  type: `${GET_INVOICES_STATEMENS_PRINT}_REQUEST`,
  payload
});

export const manageCardNameAction = (payload) => ({
  type: `${MANAGE_CARD_NAME}_REQUEST`,
  payload
});

export const viewPinAction = (payload) => ({
  type: `${VIEW_PIN}_REQUEST`,
  payload
});

export const changePinAction = (payload) => ({
  type: `${CHANGE_PIN}_REQUEST`,
  payload
});

export const lostCardAction = (payload) => ({
  type: `${LOST_CARD}_REQUEST`,
  payload
});

export const cardManageBlockAction = (payload) => ({
  type: `${BLOCK_CARD}_REQUEST`,
  payload
});

// ---------------------------------------------------------------------------------------------------------
export const applyCardAction = (payload) => ({
  type: `${APPLY_CARD}_REQUEST`,
  payload
});

export const getCardSteatmentsViewAction = (payload) => ({
  type: `${GET_CARD_STEATMENTS_VIEW}_REQUEST`,
  payload
});

export const getCardInvoicesViewAction = (payload) => ({
  type: `${GET_CARD_INVOICES_VIEW}_REQUEST`,
  payload
});

export const resetData = (payload) => ({
  type: RESET_DATA,
  payload: payload ? payload : false
});

export const resetCardsError = () => ({
  type: RESET_CARDS_ERRORS
});
